// font family
@sansFont:"Helvetica Neue",Helvetica,Arial,sans-serif;
@serifFont:Georgia, "Times New Roman",Times,serif;
@wpFont:"Microsoft YaHei",sans-serif;
@bizFont:"Helvetica Neue",Helvetica,"Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
@globalFont:@sansFont;
@globalFontSize:14px;

// font color
@globalLinkColor: #61749B;
@globalDescColor: #B2B2B2;
@globalWarnColor: #E64340;
@globalNickNameColor: #576B95;
@globalTextColor: #888;
@globalTitleColor: #000;

//border
@globalBorderColor: #BCBAB6;

//arrow
@globalArrowColor: #C7C7CC;

//component
@itemActiveColor: #E4E4E4;

//page
@pageDefaultBackgroundColor:#EFEFF4;

// gap
@gap5:5px;
@gap10:10px;
@gap15:15px;
