@import "../../base/fn";

.weui_grids {
    position: relative;
    overflow: hidden;

    &:before {
        .setTopLine(@weuiGridBorderColor);
    }
    &:after {
        .setLeftLine(@weuiGridBorderColor);
    }
}

.weui_grid {
    position: relative;
    float: left;
    padding: 20px 10px;
    width: 100% / @weuiGridColumnCount;
    box-sizing: border-box;

    &:before {
        .setRightLine(@weuiGridBorderColor);
        right: -1px;
    }
    &:after {
        .setBottomLine(@weuiGridBorderColor);
    }

    &:active {
        background-color: @itemActiveColor;
    }
}

.weui_grid_icon {
    width: @weuiGridIconSize;
    height: @weuiGridIconSize;
    margin: 0 auto;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }

    & + .weui_grid_label{
        margin-top: 5px;
    }
}

.weui_grid_label {
    display: block;
    text-align: center;
    color: @globalTitleColor;
    font-size: @weuiGridFontSize;
}