@import "../../base/fn";
// 存在样式优先级的问题，故这里对其他weui_btn的引用放在底部
// 主要是button.weui_btn在weui_btn_plain下重写border-width

.weui_btn {
    &.weui_btn_mini {
        line-height: @weuiBtnMiniHeight;
        font-size: @weuiBtnMiniFontSize;
        padding: 0 .75em;
        display: inline-block;
    }
}

button, input {
    &.weui_btn {
        width: 100%;
        border-width: 0;
        outline: 0;
        -webkit-appearance: none;
        &:focus {
            outline: 0;
        }
    }
    &.weui_btn_inline,&.weui_btn_mini {
        width: auto;
    }
}

/*gap between btn*/
.weui_btn + .weui_btn {
    margin-top: @weuiBtnDefaultGap;
}

.weui_btn.weui_btn_inline + .weui_btn.weui_btn_inline {
    margin-top: auto;
    margin-left: @weuiBtnDefaultGap;
}

.weui_btn_area {
    margin: @weuiCellsMarginTop @weuiBtnDefaultGap .3em;
    &.weui_btn_area_inline {
        -webkit-display: flex;
        display: flex;
        .weui_btn {
            margin-top: auto;
            margin-right: @weuiBtnDefaultGap;
            width: 100%;
            -webkit-flex: 1;
            flex: 1;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@import "weui_btn_global";
@import "weui_btn_default";
@import "weui_btn_primary";
@import "weui_btn_warn";
@import "weui_btn_disabled";
@import "weui_btn_plain";