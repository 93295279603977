@import "../../base/fn";

@weuiDialogBackgroundColor: #FAFAFC;
@weuiDialogLineColor: #D5D5D6;
@weuiDialogLinkColor: #3CC51F;
@weuiDialogLinkActiveBc: #EEEEEE;

.weui_dialog {
    position: fixed;
    z-index: 13;
    width: 85%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: @weuiDialogBackgroundColor;
    text-align: center;
    border-radius: 3px;
    .weui_dialog_confirm & {
        .weui_dialog_hd {
            padding: 1.2em 20px .5em;
        }
        .weui_dialog_bd {
            text-align: left;
        }
    }
}

.weui_dialog_hd {
    padding: 1.2em 0 .5em;
}

.weui_dialog_title {
    font-weight: 400;
    font-size: 17px;
}

.weui_dialog_bd {
    padding: 0 20px;
    font-size: 15px;
    color: @globalTextColor;
}

.weui_dialog_ft {
    position: relative;
    line-height: 42px;
    margin-top: 20px;
    font-size: 17px;
    display: flex;
    a {
        display: block;
        flex: 1;
        color: @weuiDialogLinkColor;
        text-decoration: none;
        .setTapColor;
        &:active {
            background-color: @weuiDialogLinkActiveBc;
        }
    }
    &:after {
        content: " ";
        .setTopLine(@weuiDialogLineColor);
    }
    .weui_dialog_confirm & {
        a {
            position: relative;
            &:after {
                content: " ";
                .setLeftLine(@weuiDialogLineColor);
            }
            &:first-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.weui_btn_dialog {
    &.default {
        color: #353535;
    }
    &.primary {
        color: #0BB20C;
    }
}

@media screen and (min-width: 1024px) {
    .weui_dialog {
        width: 35%;
    }
}
